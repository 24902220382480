
import { Prop, Component } from '@/lib/decorator';
import FormEditor from '@/lib/FormEditor';

import FormInput from '@/components/form/Input/index.vue';
import FormTextarea from '@/components/form/Textarea/index.vue';
import FormButton from '@/components/form/Button/index.vue';

import CustomersHelperEntityModule from '@/store/customers/helpersEntity';
import { strings } from '@/lib/stringConst';
import { ResponseParams } from '@/store/customers/helpersEntity';
import { CustomerHelperEntityModel } from '@/interfaces/models/customer.interface';

@Component({
  emits: ['returnBefore'],
  components: {
    FormInput,
    FormTextarea,
    FormButton,
  },
})
export default class CustomerHelpersForm extends FormEditor {
  @Prop() typeAction!: string;

  lastName = this.model.lastName ?? '';
  firstName = this.model.firstName ?? '';
  middleName = this.model.middleName ?? '';
  phone = this.model.phone ?? '';
  preparePhone = '';
  email = this.model.email ?? '';
  comment = this.model.comment ?? '';
  party = this.model.party ?? '';

  get model(): CustomerHelperEntityModel {
    return CustomersHelperEntityModule.model;
  }

  get text(): Record<string, string> {
    return strings;
  }

  get responseData(): ResponseParams {
    let out = {
      client: this.$route.params.customerId as string,
    };

    if (this.typeAction === 'edit' && this.$route.params.helperId) {
      Object.assign(out, {
        helperId: this.$route.params.helperId as string,
      });
    }

    if (this.firstName) {
      Object.assign(out, {
        firstName: this.firstName,
      });
    }

    if (this.lastName) {
      Object.assign(out, {
        lastName: this.lastName,
      });
    }

    if (this.middleName) {
      Object.assign(out, {
        middleName: this.middleName,
      });
    }

    if (this.preparePhone) {
      Object.assign(out, {
        phone: this.preparePhone,
      });
    }

    if (this.email) {
      Object.assign(out, {
        email: this.email,
      });
    }

    if (this.party) {
      Object.assign(out, {
        party: this.party,
      });
    }

    if (this.comment) {
      Object.assign(out, {
        comment: this.comment,
      });
    }

    return out;
  }

  returnBefore(): void {
    this.$emit('returnBefore');
  }

  async add(): Promise<void> {
    try {
      await CustomersHelperEntityModule.addNew(Object.assign({}, this.responseData));
      this.returnBefore();
    } catch (e) {
      console.debug('e');
    }
  }

  async edit(): Promise<void> {
    try {
      await CustomersHelperEntityModule.saveUpdate(Object.assign({}, this.responseData));
      this.returnBefore();
    } catch (err) {
      console.debug(err);
    }
  }
}
