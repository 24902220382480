import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "cp-form__row" }
const _hoisted_2 = { class: "cp-form__column" }
const _hoisted_3 = { class: "cp-form__layout" }
const _hoisted_4 = { class: "cp-margin__tb--sm cp-form__row cp-form-btn-actions__wrapper cp-padding__lr" }
const _hoisted_5 = { class: "cp-form__column" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_form_input = _resolveComponent("form-input")!
  const _component_form_textarea = _resolveComponent("form-textarea")!
  const _component_form_button = _resolveComponent("form-button")!

  return (_openBlock(), _createElementBlock("form", {
    ref: "form",
    class: "cp-form__row",
    onSubmit: _cache[8] || (_cache[8] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.save && _ctx.save(...args)), ["prevent"]))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_form_input, {
            modelValue: _ctx.lastName,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.lastName) = $event)),
            label: "Фамилия",
            required: "",
            class: "cp-width--304"
          }, null, 8, ["modelValue"]),
          _createVNode(_component_form_input, {
            modelValue: _ctx.firstName,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.firstName) = $event)),
            label: "Имя",
            required: "",
            class: "cp-width--304"
          }, null, 8, ["modelValue"]),
          _createVNode(_component_form_input, {
            modelValue: _ctx.middleName,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.middleName) = $event)),
            label: "Отчество",
            class: "cp-width--304"
          }, null, 8, ["modelValue"]),
          _createVNode(_component_form_input, {
            modelValue: _ctx.phone,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.phone) = $event)),
            modifyValue: _ctx.preparePhone,
            "onUpdate:modifyValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.preparePhone) = $event)),
            label: "Телефон",
            type: "phone",
            class: "cp-width--200"
          }, null, 8, ["modelValue", "modifyValue"]),
          _createVNode(_component_form_input, {
            modelValue: _ctx.email,
            "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.email) = $event)),
            label: "E-mail",
            type: "email",
            class: "cp-width--200"
          }, null, 8, ["modelValue"]),
          _createVNode(_component_form_input, {
            modelValue: _ctx.party,
            "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.party) = $event)),
            label: "Группа",
            class: "cp-width--200"
          }, null, 8, ["modelValue"]),
          _createVNode(_component_form_textarea, {
            modelValue: _ctx.comment,
            "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.comment) = $event)),
            label: "Комментарий",
            class: "cp-margin__t--small"
          }, null, 8, ["modelValue"])
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_form_button, {
          text: _ctx.text.btnSave,
          type: 'submit',
          class: "cp-margin__r--sx"
        }, null, 8, ["text"]),
        _createVNode(_component_form_button, {
          text: _ctx.text.btnCancel,
          bordered: true,
          onClick: _ctx.returnBefore
        }, null, 8, ["text", "onClick"])
      ])
    ])
  ], 544))
}